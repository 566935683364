import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Navbar.css';
import logo from '../media/images/logo.webp';

const Navbar = () => {
    return (
        <header>
            <nav className="navbar" role="navigation" aria-label="Main Navigation">
                <div className="navbar-logo">
                    <Link to="/" aria-label="PhoneFinder Home">
                        <img src={logo} alt="PhoneFinder Logo" width="150" height="auto" />
                    </Link>
                </div>
                <div className="navbar-title">
                    <Link to="/" className="navbar-title-link" aria-label="PhoneFinder Home">
                        <h1 className="navbar-title-text">MobileLocalisation - Localiser rapidement un téléphone</h1>
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
