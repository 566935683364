import React from 'react';
import './styles/CGV.css';

const CGV = () => {
    return (
        <div className="cgv-container">
            <h1>Conditions Générales d'Utilisation et de Vente</h1>
            
            <section className="cgv-section">
                <h2>1. Préambule</h2>
                <p>
                    Les présentes conditions générales d'utilisation et de vente (ci-après « CGUV ») régissent les modalités et les conditions dans lesquelles la société <strong>Dreamer Agency</strong> (ci-après « la Société ») fournit ses services via le site web <strong>www.mobilelocalisation.com</strong> (ci-après « le Site »). En accédant au Site et en utilisant ses services, l'utilisateur accepte sans réserve les présentes CGUV.
                </p>
            </section>

            <section className="cgv-section">
                <h2>2. Services proposés</h2>
                <p>
                    La Société propose un service de localisation de téléphones mobiles (ci-après « le Service ») accessible depuis le Site. Ce Service permet aux utilisateurs de géolocaliser un téléphone mobile après consentement du titulaire du numéro de téléphone. La localisation est obtenue via des technologies de géolocalisation telles que GPS, Wi-Fi, et autres méthodes. Le Service est accessible en France.
                </p>
            </section>

            <section className="cgv-section">
                <h2>3. Conditions d'accès et d'utilisation</h2>
                <p>
                    L'accès au Service est réservé aux personnes majeures et juridiquement capables. L'utilisateur doit posséder un appareil connecté à Internet et un navigateur compatible pour accéder au Site et au Service. La géolocalisation d'un téléphone mobile via le Service nécessite le consentement explicite du titulaire du numéro de téléphone. La Société se réserve le droit de suspendre ou de résilier l'accès au Service en cas de non-respect des présentes CGUV.
                </p>
            </section>

            <section className="cgv-section">
                <h2>4. Tarifs et modalités de paiement</h2>
                <p>
                    L'utilisation du Service est soumise à des frais, détaillés sur le Site. Un essai initial est proposé au tarif de 1.99€ pour une période de 24h, après quoi un abonnement mensuel au tarif de 29.99€ est automatiquement appliqué. Le paiement est effectué par carte bancaire via un système sécurisé de paiement en ligne. L'utilisateur autorise la Société à débiter automatiquement les frais d'abonnement sur le moyen de paiement fourni, sauf en cas de résiliation dans les conditions décrites dans les présentes CGUV.
                </p>
                <p>
                    En cas de non-paiement ou de retard de paiement, la Société se réserve le droit de suspendre ou de résilier l'accès au Service sans préavis. Les factures sont disponibles sur demande en contactant la Société via l'adresse email fournie dans les présentes CGUV.
                </p>
            </section>

            <section className="cgv-section">
                <h2>5. Droits et obligations de l'utilisateur</h2>
                <p>
                    L'utilisateur s'engage à utiliser le Service de manière légale et éthique. Il est strictement interdit d'utiliser le Service à des fins de surveillance non autorisée ou de harcèlement. L'utilisateur est responsable de la confidentialité de ses identifiants et de l'usage qui en est fait. Toute violation des présentes CGUV par l'utilisateur peut entraîner la suspension ou la résiliation de son accès au Service. L'utilisateur reconnaît et accepte que la géolocalisation sans consentement constitue une violation de la vie privée et peut entraîner des poursuites judiciaires.
                </p>
            </section>

            <section className="cgv-section">
                <h2>6. Responsabilité de la Société</h2>
                <p>
                    La Société s'engage à fournir le Service avec diligence et conformément aux standards du secteur. Toutefois, la Société ne saurait être tenue responsable en cas de dysfonctionnements ou interruptions du Service dus à des causes extérieures telles que des pannes de réseau, des erreurs de localisation imputables à des limitations technologiques, ou des actions malveillantes de tiers. La Société ne garantit pas l'exactitude absolue des localisations fournies par le Service.
                </p>
                <p>
                    La Société décline toute responsabilité en cas d'utilisation frauduleuse ou abusive du Service par un utilisateur ou un tiers. En aucun cas, la Société ne pourra être tenue responsable des dommages indirects, y compris, mais sans s'y limiter, la perte de revenus, de données ou d'opportunités commerciales.
                </p>
            </section>

            <section className="cgv-section">
                <h2>7. Données personnelles</h2>
                <p>
                    La Société respecte la vie privée des utilisateurs et se conforme à la réglementation en vigueur en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD). Les informations collectées lors de l'utilisation du Service sont traitées de manière confidentielle et ne sont utilisées que dans le cadre de la fourniture du Service. L'utilisateur dispose d'un droit d'accès, de rectification et de suppression de ses données personnelles en contactant la Société à l'adresse suivante : <strong>contact@dreameragency.fr</strong>.
                </p>
                <p>
                    La Société s'engage à ne pas partager ou vendre les données personnelles des utilisateurs à des tiers sans leur consentement préalable, sauf dans les cas prévus par la loi.
                </p>
            </section>

            <section className="cgv-section">
                <h2>8. Résiliation</h2>
                <p>
                    L'utilisateur peut résilier son abonnement à tout moment en suivant la procédure décrite sur le Site. La résiliation prend effet à la fin de la période de facturation en cours, sous réserve que la demande de résiliation ait été reçue par la Société au moins 12 heures avant la fin de cette période. Dans le cas contraire, la résiliation pourrait prendre effet à la période de facturation suivante. La Société se réserve le droit de résilier l'abonnement de l'utilisateur sans préavis en cas de non-respect des présentes CGUV.
                </p>
            </section>

            <section className="cgv-section">
                <h2>9. Propriété intellectuelle</h2>
                <p>
                    Tous les éléments du Site, y compris les textes, graphiques, logos, icônes, images, clips audio et logiciels, sont la propriété exclusive de la Société ou de ses partenaires et sont protégés par le droit d'auteur, le droit des marques et/ou tout autre droit de propriété intellectuelle. Toute reproduction, distribution, modification ou exploitation de ces éléments, en tout ou en partie, sans l'autorisation préalable et écrite de la Société, est strictement interdite.
                </p>
            </section>

            <section className="cgv-section">
                <h2>10. Modification des CGUV</h2>
                <p>
                    La Société se réserve le droit de modifier les présentes CGUV à tout moment. Les modifications entreront en vigueur dès leur publication sur le Site. L'utilisateur est invité à consulter régulièrement les CGUV pour prendre connaissance des éventuelles modifications. L'utilisation continue du Service après toute modification des CGUV constitue une acceptation des nouvelles conditions.
                </p>
            </section>

            <section className="cgv-section">
                <h2>11. Droit applicable et juridiction compétente</h2>
                <p>
                    Les présentes CGUV sont régies par le droit Français. En cas de litige relatif à l'interprétation ou à l'exécution des présentes CGUV, les parties conviennent de rechercher une solution amiable avant de recourir aux tribunaux compétents de Paris.
                </p>
            </section>

            <section className="cgv-section">
                <h2>12. Contact</h2>
                <p>
                    Pour toute question ou réclamation relative aux présentes CGUV ou à l'utilisation du Service, l'utilisateur peut contacter la Société à l'adresse suivante : <strong>contact@dreameragency.fr</strong>.
                </p>
            </section>
        </div>
    );
};

export default CGV;
