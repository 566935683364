import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

import './styles/PaymentForm.css';

const PaymentForm = (props) => {
  const { phone } = props;
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: event.target.cardName.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        address: {
          country: event.target.country.value,
          line1: event.target.address.value,
        },
      },
    });

    if (error) {
      console.error('Error creating payment method:', error);
      alert(`Payment failed: ${error.message}`);
      return;
    }

    console.log('Payment Method Created:', paymentMethod);

    try {
      const response = await fetch(`${apiUrl}/payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          phone: phone,
          billing_details: {
            name: event.target.cardName.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            address: {
              country: event.target.country.value,
              line1: event.target.address.value,
            },
          },
        }),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Payment successful:', result);
        navigate('/payment-success');
      } else {
        console.error('Payment failed:', result.error);
        alert(`Payment failed: ${result.error.message}`);
      }
    } catch (error) {
      console.error('Error sending payment to backend:', error);
      alert(`Payment failed: ${error.message}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="form-group">
        <label>Nom</label>
        <input type="text" name="cardName" className="form-control" placeholder="Nom" required />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="email" name="email" className="form-control" placeholder="Email" required />
      </div>

      <div className="form-group">
        <label>Téléphone</label>
        <input type="tel" name="phone" className="form-control" placeholder="Numéro de téléphone" required />
      </div>
        
      <div className="form-group">
        <label>Pays</label>
        <select name="country" className="form-control" required>
          <option value="US">United States</option>
          <option value="FR">France</option>
          <option value="GB">United Kingdom</option>
          <option value="DE">Germany</option>
          <option value="CA">Canada</option>
        </select>
      </div>
        
      <div className="form-group">
        <label>Adresse</label>
        <input type="text" name="address" className="form-control" placeholder="Adresse" required />
      </div>
        
      <div className="form-group">
        <label>Numéro de carte</label>
        <CardElement className="form-control" />
      </div>
        
      <button type="submit" className="submit-button">Payer</button>
    </form>
  );
};

export default PaymentForm;
