import React, { useState } from 'react';
import './styles/CancelSubscription.css';

const CancelSubscription = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage('Votre abonnement a été résilié avec succès.');
      } else {
        setMessage(`Erreur : ${result.error}`);
      }
    } catch (error) {
      setMessage(`Erreur : ${error.message}`);
    }
  };

  return (
    <main className="cancel-container">
      <div className="cancel-row justify-content-center">
        <div className="cancel-col-md-6">
          <div className="cancel-card">
            <div className="cancel-card-body">
              <h1 className="cancel-card-title text-center">Formulaire de Résiliation d'Abonnement</h1>
              <div className="cancel-alert cancel-alert-success" role="alert">
                Vous avez un abonnement en cours avec MobileLocalisation ? Vous pouvez facilement demander la résiliation de votre abonnement en remplissant ce formulaire.
              </div>
              {message && <div className="cancel-alert cancel-alert-info">{message}</div>}
              <form onSubmit={handleSubmit}>
                <div className="cancel-mb-3">
                  <label htmlFor="emailInput" className="cancel-form-label">Adresse e-mail</label>
                  <input
                    type="email"
                    className="cancel-form-control"
                    id="emailInput"
                    name="email"
                    placeholder="Entrez votre e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="cancel-btn cancel-btn-danger">Résilier maintenant</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CancelSubscription;
