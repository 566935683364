import React from 'react';
import { Link } from 'react-router-dom';

import './styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-heading">
                    <h4>MobileLocalisation - Votre Service de Localisation Mobile Fiable</h4>
                </div>
                <div className="footer-button">
                    <a className="footer-locate-button" href="#top">Localiser mon numéro</a>
                </div>
                <div className="footer-links">
                    <p>&copy; Tous droits réservés.</p>
                    {/*<p><a href="geo.php">Suivre ma demande de localisation</a></p>*/}
                    <p><Link to="/cancel-subscription">Se désabonner : Annuler mon abonnement</Link></p>
                    <p><Link to="/terms-conditions">Mentions légales : Conditions générales de vente</Link></p>
                    <p>Dreamer Agency</p>
                </div>
                <div className="footer-legal">
                    <p>Le service MobileLocalisation est un service de partage de localisation de mobile. Ce service coûte 1,99€ TTC pour une période de 24 heures, puis 29,99€ par mois. Le délai de résiliation peut aller jusqu'à 12h à compter de la demande de résiliation.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
