import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { useLocation } from 'react-router-dom';

import "./styles/Payment.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const Payment = () => {
    const location = useLocation();
    const { phone } = location.state || {};

    return (
        <main>
            <Elements stripe={stripePromise}>
                <div className="payment-container">
                    <h2>Paiement sécurisé</h2>
                    {phone && <p>Localisation pour le numéro : {phone}</p>}
                    <PaymentForm phone={phone} />
                </div>
            </Elements>
        </main>
    );
};

export default Payment;
