import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles/Home.css';

import geoloc from '../media/images/geoloc.webp';
import shield from '../media/images/shield.webp';
import globe from '../media/images/globe.webp';
import gps from '../media/images/gps.webp';
import friend from '../media/images/find_friend.webp';
import steal from '../media/images/find_steal.webp';
import lost from '../media/images/find_lost.webp';

const Home = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const phone = event.target.phone.value;

    navigate('/payment', { state: { phone } });
  };

  return (
    <main className="home-main-content">
      <section className="home-main-banner">
        <div className="home-container">
          <div className="home-row">
            <div className="home-col-lg-6 home-align-self-center">
              <div className="home-left-content home-header-text">
                <h2>Localisez un Téléphone Mobile en Moins de 2 Minutes</h2>
                <p className="home-tagline">Obtenez instantanément la position géographique précise de n'importe quel mobile grâce à notre service sécurisé et rapide.</p>
                <form className="home-form" onSubmit={handleSubmit}>
                  <div className="home-form-group">
                    <label htmlFor="phone" className="home-form-label">Numéro de téléphone</label>
                    <input
                      type="tel"
                      id="phone"
                      className="home-form-control"
                      required
                      name="phone"
                      minLength="10"
                      maxLength="14"
                      placeholder="Entrez le numéro de téléphone à localiser"
                    />
                  </div>
                  <button type="submit" className="home-gradient-button">
                    LOCALISER MON NUMÉRO
                  </button>
                </form>
                <div className="home-icons">
                  <div className="home-icon-item">
                    <img src={globe} alt="Service Disponible Mondialement" width="50" height="50" />
                    <p>Service Disponible<br /> Mondialement</p>
                  </div>
                  <div className="home-icon-item">
                    <img src={gps} alt="Précision GPS Assurée" width="50" height="50" />
                    <p>Précision GPS<br /> Assurée</p>
                  </div>
                  <div className="home-icon-item">
                    <img src={shield} alt="100% Légal et Sécurisé" width="50" height="50" />
                    <p>100% Légal et<br /> Sécurisé</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-col-lg-6">
              <div className="home-right-image">
                <img src={geoloc} alt="Géolocalisation Précise" width="1024" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-services home-section">
        <div className="home-container">
          <div className="home-row">
            <div className="home-col-lg-3">
              <div className="home-service-item">
                <h2>Paiement 100% Sécurisé</h2>
                <p>Vos informations bancaires sont protégées grâce à notre cryptage SSL de pointe et un accès sécurisé par HTTPS.</p>
                <button className="home-gradient-button">Localiser mon numéro</button>
              </div>
            </div>
            <div className="home-col-lg-3">
              <div className="home-service-item">
                <h2>Géolocalisation Personnalisée</h2>
                <p>Adaptez votre demande à vos besoins en renseignant vos informations personnelles pour une localisation sur mesure.</p>
                <button className="home-gradient-button">Localiser mon numéro</button>
              </div>
            </div>
            <div className="home-col-lg-3">
              <div className="home-service-item">
                <h2>Géolocalisation Ultra Précise</h2>
                <p>Obtenez des coordonnées exactes, visualisées directement sur une carte, partout dans le monde.</p>
                <button className="home-gradient-button">Localiser mon numéro</button>
              </div>
            </div>
            <div className="home-col-lg-3">
              <div className="home-service-item">
                <h2>Compatibilité Universelle</h2>
                <p>Notre service fonctionne avec toutes les marques de téléphones, tous les opérateurs et tous les systèmes d'exploitation.</p>
                <button className="home-gradient-button">Localiser mon numéro</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-about-us home-section">
        <div className="home-container2">
          <h2 className="home-section-title">Comment fonctionne la géolocalisation ?</h2>
          <p className="home-section-description">Suivez ces étapes simples pour localiser un téléphone mobile :</p>
          <div className="home-steps">
            <div className="home-step-item">
              <span className="home-number-circle">1</span>
              <div className="home-step-content">
                <h3>Renseignez le numéro de téléphone</h3>
                <p>Entrez le numéro du mobile que vous souhaitez localiser et démarrez la géolocalisation instantanément.</p>
              </div>
            </div>
            <div className="home-step-item">
              <span className="home-number-circle">2</span>
              <div className="home-step-content">
                <h3>Paiement sécurisé</h3>
                <p>Effectuez votre paiement via notre système ultra sécurisé et crypté.</p>
              </div>
            </div>
            <div className="home-step-item">
              <span className="home-number-circle">3</span>
              <div className="home-step-content">
                <h3>Le destinataire reçoit un SMS</h3>
                <p>Le destinataire reçoit un SMS et accepte le partage de sa localisation en un clic.</p>
              </div>
            </div>
            <div className="home-step-item">
              <span className="home-number-circle">4</span>
              <div className="home-step-content">
                <h3>Recevez la position</h3>
                <p>Obtenez la position géographique exacte du téléphone par email et SMS.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-clients home-section">
        <div className="home-container">
          <div className="home-row">
            <div className="home-col-lg-8 home-offset-lg-2">
              <div className="home-section-heading">
                <h2>Un service <em>compatible</em> avec tous les téléphones mobiles</h2>
              </div>
            </div>
            <div className="home-col-lg-12">
              <div className="home-naccs">
                <div className="home-grid">
                  <div className="home-row">
                    <div className="home-col-lg-12">
                      <ul className="home-nacc">
                        <li className="home-active">
                          <div className="home-client-content-container">
                            <div className="home-thumb">
                              <p>Avec MobileLocalisation, localisez facilement votre téléphone, que ce soit sous Android ou iOS, quel que soit l'opérateur. Profitez d'une première localisation à 1.99€, puis d'un abonnement mensuel à 29,99€ pour un suivi continu.</p>
                              <div className="home-client-action">
                                <a href="#top" className="home-gradient-button">Localiser mon numéro</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="home-client-content-container"></div>
                        </li>
                      </ul>
                    </div>          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="home-pricing" className="home-pricing-tables">
        <div className="home-container">
          <div className="home-row">
            <div className="home-col-lg-4">
              <div className="home-pricing-item-pro">
                <h3>Localiser un proche</h3>
                <div className="home-icon">
                  <img src={friend} alt="Localiser un proche" width="80" height="80" />
                </div>
                <p>Que ce soit un ami, un conjoint ou un membre de votre famille, notre service vous permet de localiser vos proches avec précision. Visualisez leur emplacement exact sur une carte et retrouvez-les rapidement.</p>
                <div className="home-col-lg-12">
                  <div className="home-gradient-button">
                    <a href="#top">Localiser mon numéro</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-col-lg-4">
              <div className="home-pricing-item-pro">
                <h3>Localiser un téléphone volé</h3>
                <div className="home-icon">
                  <img src={steal} alt="Localiser un téléphone volé" width="80" height="80" />
                </div>
                <p>Retrouver un téléphone volé n'a jamais été aussi simple. Grâce à notre service de géolocalisation, identifiez rapidement l'emplacement de votre appareil volé et augmentez vos chances de le récupérer.</p>
                <div className="home-col-lg-12">
                  <div className="home-gradient-button">
                    <a href="#top">Localiser mon numéro</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-col-lg-4">
              <div className="home-pricing-item-pro">
                <h3>Localiser un téléphone perdu</h3>
                <div className="home-icon">
                  <img src={lost} alt="Localiser un téléphone perdu" width="80" height="80" />
                </div>
                <p>Ne perdez plus de temps à chercher votre téléphone. Utilisez notre service pour obtenir la localisation exacte de votre appareil perdu, et retrouvez-le en un rien de temps.</p>
                <div className="home-col-lg-12">
                  <div className="home-gradient-button">
                    <a href="#top">Localiser mon numéro</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
