import './App.css';

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Payment from "./components/Payment";
import PaymentSuccess from "./components/PaymentSuccess";
import CancelSubscription from "./components/CancelSubscription";
import CGV from "./components/CGV";

function App() {

  React.useEffect(() => {
    ReactGA.initialize([
    {
        trackingId: "G-8QWPLRVV26"
      },  
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/cancel-subscription" element={<CancelSubscription />} />
        <Route path="/terms-conditions" element={<CGV />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
