import React from 'react';
import './styles/PaymentSuccess.css';

const PaymentSuccess = () => {
    return (
        <div className="payment-success-container">
            <div className="payment-success-row">
                <div className="payment-success-col-12">
                    <nav className="payment-success-main-nav">
                        <a href="/" className="payment-success-logo">
                        </a>
                    </nav>
                </div>
            </div>

            <section className="payment-success-message-section">
                <div className="payment-success-message-box">
                    <h1>Paiement accepté</h1>
                    <p>Merci pour votre paiement ! Un email avec la localisation du numéro de téléphone vous sera envoyé dès que la demande de localisation sera acceptée.</p>
                </div>
            </section>
        </div>
    );
};

export default PaymentSuccess;
